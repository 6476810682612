import { Header } from '@widgets/layout';
import { LoginButton } from '@features/login-button';
import { TextLogo } from '@ui/Logo';

const RegisterPageHeader = (props) => {
  return (
    <Header
      {...props}
      fullWidth
      containerClassName="md-up:!px-4"
    >
      <TextLogo blank />
      <LoginButton />
    </Header>
  );
};

export default RegisterPageHeader;
