import { CompanyEulaLink, CompanyPrivacyLink } from '@entities/company';
import { bem } from '@lib/bem';
import { Trans } from 'react-i18next';
import { getTypographyClass } from '@ui/Typography';
import './PrivacyFooter.scss';

const { block } = bem('PrivacyFooter');

const PrivacyFooter = () => {
  return (
    <div>
      <Trans
        i18nKey="registration.privacy_footer_text"
        components={{
          eula: <CompanyEulaLink
            className={getTypographyClass({
              variant: 'link2',
              className: 'text-primary400',
            })}
          />,
          privacy: <CompanyPrivacyLink
            className={getTypographyClass({
              variant: 'link2',
              className: 'text-primary400',
            })}
          />,
        }}
      />
    </div>
  );
};

export default PrivacyFooter;
