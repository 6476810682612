import Typography from '@ui/Typography';
import clsx from 'clsx';
import React from 'react';

import './Tag.scss';

export const Tag = ({ text, color }) => {
  const borderStyle = color ? `bg-${color}` : 'wrapper';
  return (
    <div className={clsx('p-px w-max rounded-[15px] border border-transparent', borderStyle)}>
      <div className="rounded-[14px] bg-white px-1.5 py-[5px]">
        <Typography className="text-2xs uppercase">{text}</Typography>
      </div>
    </div>
  );
};
